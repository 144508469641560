import React from "react";
//import logo from "./logo.svg";
import "./App.css";
//import "bootstrap/dist/css/bootstrap.css";

function App() {
  return (
    <div className="App">
      <video id="bgVideo" playsInline autoPlay muted loop>
        <source src="/wJewleryBG-mod.mp4" type="video/mp4" />
      </video>

      <div className="landing-menu">
        <ul>
          <li>
            <a href="#0">COLLECTION</a>
          </li>
          <li>
            <a href="#0">GET IN TOUCH</a>
          </li>
          <li className="social">
            <a
              href="https://www.instagram.com/peacockandthepalm/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fab fa-instagram" />
            </a>
          </li>
        </ul>
      </div>

      <div className="logo-landing">{/* <span>W</span> */}</div>
    </div>
  );
}

export default App;
